import { getQueryString, removeClass } from '@shein/common-function'
import { scrollFix } from 'public/src/pages/common/utils/index.js'
import { isSwitchBffApiVersion } from 'public/src/pages/common/bff-new/index'
const scroll_fix = typeof window !== 'undefined' ? scrollFix() : () => {}

let SIZE_MAP = SiteUID => {
  const map = base => ({
    '0XL': `0XL(${base})`,
    '1XL': `1XL(${base + 2})`,
    '2XL': `2XL(${base + 4})`,
    '3XL': `3XL(${base + 6})`,
    '4XL': `4XL(${base + 8})`,
    '5XL': `5XL(${base + 10})`,
  })
  switch (SiteUID) {
    case 'pwus':
      return map(12)
    case 'pwau':
    case 'pwuk':
      return map(16)
    case 'pwfr':
    case 'pwes':
    case 'pwde':
      return map(44)
    case 'pwit':
      return map(48)
  }
}

function formatSizeAttrs(SiteUID, attr) {
  if (typeof SIZE_MAP === 'function') SIZE_MAP = SIZE_MAP(SiteUID)
  if (!SIZE_MAP) return

  if (attr.show_group && attr.groups && attr.groups.length) {
    attr.groups.forEach(sub => {
      sub.group_name = SIZE_MAP[sub.group_name] || sub.group_name
    })
  } else if (!attr.show_group && attr.attr_values && attr.attr_values.length) {
    attr.attr_values.forEach(sub => {
      sub.attr_value = SIZE_MAP[sub.attr_value] || sub.attr_value
    })
  }
}

function sortAttrs(attr) {
  if (attr.show_group && attr.groups && attr.groups.length) {
    attr.groups.sort((a, b) => a.group_name > b.group_name ? 1 : -1)
  } else if (!attr.show_group && attr.attr_values && attr.attr_values.length) {
    attr.attr_values.sort((a, b) => a.attr_value > b.attr_value ? 1 : -1)
  }
}

function markAttrs(attr, hotFilterAttrs) {
  if (!hotFilterAttrs?.[attr.attr_id]) return
  const list = hotFilterAttrs[attr.attr_id].split('-')
  attr.show_group
    ? attr.groups.forEach(_ => _.hotMark = list.some(i => _.attr_filter.includes(i)))
    : attr.attr_values.forEach(_ => _.hotMark = list.includes(_.attr_filter))
}

/* ====== Goods ====== */

/* ====== Categories ====== */


/* ====== Attributes ====== */
export function preFormatFilterAttrs({ SiteUID, appLanguage, filterAttrs, hotFilterAttrs, isPlusSize, ListAttrSequence, staticAttrSet }) {
  // *. 保存当前操作属性项
  // a. 检查属性组图片完整性
  // b. 给子项补充父级id TODO: 埋点还在依赖??
  // c. 欧洲本地化尺码转换
  // d. 属性值按字母排序

  /**
   * 属性组
   *  ? 全没图
   *    ? 展示文字
   *    : 展示有图的, 没图的不要了 (组件内逻辑)
   *  : 展示文字
   */
  let hasStaticAttrSet = false // 新的筛选数据有当前操作的筛选项 （只有后端返回异常，才会没有），默认false
  filterAttrs.forEach((attr, index) => {
    // * 最后操作项直接继承不做二次处理
    if (staticAttrSet && attr.attr_id === staticAttrSet.attr_id) {
      hasStaticAttrSet = true
      return filterAttrs[index] = staticAttrSet
    }

    if (attr.show_group && attr.groups && attr.groups.length) {
      // b
      attr.groups.forEach(sub => (sub.attr_id = attr.attr_id))
      // a
      attr.show_group_txt = attr.groups.every(sub => !sub.group_image)
    } else if (!attr.show_group && attr.attr_values && attr.attr_values.length) {
      // b
      attr.attr_values.forEach(sub => {
        sub.attr_id = attr.attr_id
        sub?.attr_values?.forEach(subSub => (subSub.attr_id = attr.attr_id))
      })
    }
    // c
    if (attr.attr_id == 87 && isPlusSize) formatSizeAttrs(SiteUID, attr)
    // d
    // RECOMMEND_FILTER_ABT_COMPLETE 值为 'on' 时，下面的代码可删除
    if (attr.attr_id !== 87 && appLanguage === 'en' && ListAttrSequence) {
      markAttrs(attr, hotFilterAttrs)
      sortAttrs(attr)
    }
  })

  // 筛选后，如果后端没有返回当前操作的筛选项，操作面板（panel）会收起，
  // 此时body中‘S-popup-parent__hidden’这个类名的样式，会导致整个页面无法滚动
  // 所以这种异常情况，需要手动解锁
  if (staticAttrSet && !hasStaticAttrSet) {
    const el = document.querySelector('body')
    removeClass({ el, cls: 'S-popup-parent__hidden' })
    scroll_fix(false, true)
  }
}

// 对锦囊搜索属性词按坑位进行分组
export function partitionCellSearchWords(positions, words, minSize, maxSize) {
  const result = {}

  for (let i = 0, j = 0; i < positions.length && j < words.length;) {
    const position = positions[i]
    const group = words[j]
    const itemCount = group.length
    // 把每个分组的个数变为偶数
    const items = itemCount % 2 === 0 ? group.slice() : group.slice(0, -1)
    if (items.length >= minSize) {
      result[position - 1] = items.slice(0, maxSize)
      i++
      j++
    } else {
      j++
    }
  }

  return result
}

// 各排序值
export const SORT_RECOMMED_VALUE = 0
export const SORT_MOST_POPULAR_VALUE = 8
export const SORT_NEW_ARRIVALS_VALUE = 9
export const SORT_REVIEW_VALUE = 7
export const SORT_PRICE_ASC_VALUE = 10
export const SORT_PRICE_DESC_VALUE = 11
export const PICKED_INFO_PROD_SORT_PRICE_ASC_VALUE = 11
export const PICKED_INFO_PROD_SORT_PRICE_DESC_VALUE = 10
export const PICKED_INFO_PROD_SORT_NEW_ARRIVALS_VALUE = 9
export const PICKED_INFO_PROD_SORT_MOST_POPULAR_VALUE = 8

/**
 * 是否是信息流落地页
 * @param {string} pageName 页面名字
 * @returns {boolean}
 */
const isPickedInfoPage = (pageName) => pageName == 'page_picked'

/**
 * 是否是实时反馈落地页
 * @param {string} pageName 页面名字
 * @returns {boolean}
 */
export const isFeedbackRecPage = (pageName) => pageName == 'page_real_time_feedback'

// 是否信息流的落地页 和 实时反馈落地页
export const isRecProductsPage = ({ pageName })  => {
  const FEEDBACK_REC_V1 = 'feedback_rec_v1'
  const bffSwitchVersion = isSwitchBffApiVersion([FEEDBACK_REC_V1])
  const isFeedbackRecSwitchBff = bffSwitchVersion?.[FEEDBACK_REC_V1]
  const isPicked = isPickedInfoPage(pageName) // 信息流落地页
  const isFeedbackRec = isFeedbackRecPage(pageName) && !isFeedbackRecSwitchBff // 实时反馈落地页
  if (isPicked || isFeedbackRec) {
    return true
  }

  return false

}

export const getRecommend = (language) => {
  return {
    label: language.SHEIN_KEY_PWA_15555,
    label_en: 'Recommend',
    value: SORT_RECOMMED_VALUE,
  }
}
export const getMostPopular = (language, pageName) => {
  return {
    label: language.SHEIN_KEY_PWA_15556,
    label_en: 'Most Popular',
    value: isRecProductsPage({ pageName }) ? PICKED_INFO_PROD_SORT_MOST_POPULAR_VALUE : SORT_MOST_POPULAR_VALUE,
  }
}
export const getNewArrivals = (language, pageName) => {
  return {
    label: language.SHEIN_KEY_PWA_15557,
    label_en: 'New Arrivals',
    value: isRecProductsPage({ pageName }) ? PICKED_INFO_PROD_SORT_NEW_ARRIVALS_VALUE : SORT_NEW_ARRIVALS_VALUE,
  }
}
export const getReview = (language) => {
  return {
    label: language.SHEIN_KEY_PWA_16891,
    label_en: 'Review',
    value: SORT_REVIEW_VALUE,
  }
}
export const getPriceAsc = (language, pageName) => {
  return {
    label: language.SHEIN_KEY_PWA_15558,
    label_en: 'Price Low To High',
    value: isRecProductsPage({ pageName }) ? PICKED_INFO_PROD_SORT_PRICE_ASC_VALUE : SORT_PRICE_ASC_VALUE,
  }
}
export const getPriceDesc = (language, pageName) => {
  return {
    label: language.SHEIN_KEY_PWA_15559,
    label_en: 'Price High To Low',
    value: isRecProductsPage({ pageName }) ? PICKED_INFO_PROD_SORT_PRICE_DESC_VALUE : SORT_PRICE_DESC_VALUE,
  }
}

// 获取属性外露展示配置 wiki.pageId=1178849879
export function getAttrExporConfigByAbt({ ListFilter, MostPopular, pageName }) {
  const { TiledListFilter = '',  ShowSoft = '' } = ListFilter?.p || {}
  const isExportFilter = TiledListFilter !== 'A' // 是否外露筛选排序
  const isShowMostPopular = MostPopular?.p?.ShowMostPopular === 'YES' // 是否展示MostPopular
  const isShowPriceBar = isExportFilter && /Price/i.test(ShowSoft) // 是否外露Price筛选项
  const isShowMostPopularBar = isShowMostPopular && isExportFilter && /Popular/i.test(ShowSoft) // 是否外露Popular筛选项
  const isShowMostPopularInSort = isShowMostPopular && !isShowMostPopularBar // 是否展示 Most Popluar 在Sort筛选项内
  const sortType = getSortType({ isShowMostPopularBar, isShowPriceBar, pageName })

  return {
    sortType,
    isExportFilter,
    isShowMostPopular,
    isShowPriceBar,
    isShowMostPopularBar,
    isShowMostPopularInSort,
    isExportFilterSort: ['C', 'D', 'F', 'G'].includes(TiledListFilter)
  }
}

// 获取吸顶配置
export function getStickyConfig ({ catInfo, listAbtResult, picTopNav = [], picksMenuList = [], bannerTag, showStoreDefaultWords, isTopTrendResultPage }) {
  const { type, pageName, search_type } = catInfo
  const { SheinPicksNavigation, Listshowsearch, flowshowsearch } = listAbtResult
  const { hidePicTopNav, isStoreSearch, isBrandSearch } = getSearchDiff({ pageName, search_type })
  const isShowPicTopNav = picTopNav.length && !hidePicTopNav
  let isStickyPicToNav = false // 是否设置吸顶图文导航
  let isStickyFilter = false // 是否设置吸顶筛选导航
  let isStickyNavBar = false // 是否设置排序栏吸顶, 即isStickyFilter
  let isStickyCloudTags = false // 是否设置吸顶标签云
  let isStickySearch = type === 'search' // 是否设置吸顶搜索框
  const isStickyOneClickTag = !!bannerTag?.showTag && bannerTag.type === 'oneClickPay' // 是否设置吸顶一键购标签

  const abtListshowsearch = Listshowsearch?.param?.Listshowsearch === 'show' // 真实+选品 列表
  const abtRealListshowsearch = Listshowsearch?.param?.CListshowsearch === 'show' // 真实 列表
  const abtSelectLsitshowsearch = Listshowsearch?.param?.SCListshowsearch === 'show' // 选品 列表
  // 真实场景
  if (type === 'entity' && (abtListshowsearch || abtRealListshowsearch)) isStickySearch = true
  // 选品场景
  if (type === 'selection' && (abtListshowsearch || abtSelectLsitshowsearch)) isStickySearch = true
  // 店铺选品页搜索框强化+底纹词轮播
  if (showStoreDefaultWords) isStickySearch = true
  // picks场景
  if (type === 'picks' && Listshowsearch?.param?.PListshowsearch === 'show') isStickySearch = true
  if (pageName === 'page_picked' && flowshowsearch?.param?.flowshowsearch === 'show') isStickySearch = true
  // picks页tab是否为图文
  let isStickyPicksPic = SheinPicksNavigation?.p?.SheinPicksNavigation
    && picksMenuList.every(item => item?.image?.src)

  // 真实/选品/搜索 走新实验[pageId=1470252779]
  if (['entity', 'selection'].includes(type) || (type == 'search' && !isStoreSearch && !isBrandSearch)) {
    const { ListTopNew, SearchTopNew } = listAbtResult
    // 搜索页
    if (type === 'search') {
      if (type === 'search' && SearchTopNew?.p?.SearchTopNew === 'Labels') {
        isStickyCloudTags = true
      } else if (isShowPicTopNav) {
        isStickyPicToNav = true
      } else {
        isStickyNavBar = true
        isStickyFilter = true
      }

      // v.趋势搜索结果页屏蔽该功能
      if (isTopTrendResultPage) {
        isStickyCloudTags = true
        isStickyNavBar = true
        isStickyFilter = true
      }

    } else {
      // 真实/选品
      const { fromPageType, entranceType } = catInfo
      const listTopEntrance = ListTopNew?.p?.ListTopEntrance || ''
      const isStickyLabels = ListTopNew?.p?.ListTopNew === 'Labels'
      const isFromHomeSbc = fromPageType === 'home' && entranceType === 'sbc' // 是否从首页sbc过来的页面
      const isFromCategory = fromPageType === 'category' //  是否从category过来的页面
      if (
        (listTopEntrance === 'sbc' && isFromHomeSbc && isStickyLabels)
        || (listTopEntrance === 'category' && isFromCategory && isStickyLabels)
        || (!listTopEntrance && isStickyLabels)
      ) {
        isStickyCloudTags = true
      } else if (isShowPicTopNav) {
        isStickyPicToNav = true
      } else {
        isStickyNavBar = true
        isStickyFilter = true
      }
    }

  } else {
    if (isShowPicTopNav) {
      isStickyPicToNav = true
    } else {
      isStickyNavBar = true
      isStickyFilter = true
    }
  }


  // 测试用 TODO liao
  // isStickyPicToNav = true
  // isStickyFilter = false
  // isStickyNavBar = false
  // isStickyCloudTags = false

  // isStickyPicToNav = false
  // isStickyFilter = true
  // isStickyNavBar = true
  // isStickyCloudTags = false

  // isStickyPicToNav = false
  // isStickyFilter = false
  // isStickyNavBar = false
  // isStickyCloudTags = true


  // isStickyPicksPic = true
  // isStickyPicksPic = false
  return {
    isStickySearch,
    isStickyPicToNav,
    isStickyFilter,
    isStickyNavBar,
    isStickyCloudTags,
    isStickyOneClickTag,
    isStickyPicksPic
  }
}

function getSortType({ isShowMostPopularBar, isShowPriceBar, pageName }) {
  if (typeof window === 'undefined') {
    return ''
  }

  const sort = Number(getQueryString({ key: 'sort' }) || '0')
  let sortType = 'sort'
  const sortPriceAscValue = isRecProductsPage({ pageName }) ? PICKED_INFO_PROD_SORT_PRICE_ASC_VALUE : SORT_PRICE_ASC_VALUE
  const sortPriceDescValue = isRecProductsPage({ pageName }) ? PICKED_INFO_PROD_SORT_PRICE_DESC_VALUE : SORT_PRICE_DESC_VALUE
  const mostPopularValue = isRecProductsPage({ pageName }) ? PICKED_INFO_PROD_SORT_MOST_POPULAR_VALUE : SORT_MOST_POPULAR_VALUE

  if (sort === mostPopularValue && isShowMostPopularBar) {
    sortType = 'top1'
  } else if (sort === sortPriceAscValue || sort === sortPriceDescValue) {
    sortType = isShowPriceBar
      ? isShowMostPopularBar ? 'top2' : 'top1'
      : 'sort'
  }

  return sortType
}

/**
 * 对应abtMap中abt项
 * @typedef {Object} AbtItem
 * @property {string|Object.<string,string|undefined>|undefined} p
 * @property {string} posKey - 实验poskey
 * @property {string} poskey - 实验poskey
 * @property {string|undefined} version - 版本 v4 最新
 */

/**
 * @description: 是否展示搜索流数据
 * @param {Object} args - 参数
 * @param {Object.<string,string|undefined>|undefined} args.RelatedSearchNewParams - 新poskey
 * @param {string|undefined} args.SearchFeedHotwordParams - 旧poskey
 * @param {number[]|undefined} args.mobileGuidedSearch - ccc配置，占位信息
 * @param {string} args.requestType - 请求类型
 */
export function isShowSearchFeed({
  RelatedSearchNewParams,
  SearchFeedHotwordParams,
  mobileGuidedSearch,
  requestType
}) {
  // 非首次加载不展示
  if (requestType !== 'firstload') return false
  // 旧poskey和新poskey都没有不展示
  if (!SearchFeedHotwordParams?.includes?.('type_id=') && !RelatedSearchNewParams?.RelatedSearchPicandStr) return false
  // ccc配置没有不展示
  if (!mobileGuidedSearch?.length) return false
  return true
}

/**
 * 维护不同类型的搜索页的差异
 * @param {Object} options - 传catInfo即可。
 * @param {string} options.pageName - 当前页面的名称。
 * @param {string} options.search_type - 要执行的搜索类型。
 * @returns {{
 *   isStoreSearch: boolean,
 *   isBrandSearch: boolean,
 *   goodsItemInfoPageKey: string,
 *   showSelectOnLongClick: boolean,
 *   showFeedback: boolean,
 *   showSearchFilters: boolean,
 *   hideSearchCoupon: boolean,
 *   hideListTopInfo: boolean,
 *   hideRelatedSearch: boolean,
 *   hideFlowCellSearch: boolean,
 *   hideFlowRankingList:boolean,
 * }}
 */
export function getSearchDiff({ pageName, search_type: type } = {}) {
  /**
 * 搜索的类型
 * @readonly
 * @enum {string}
 */
  const SEARCH_TYPE_ENUMS = {
    store: 'store',
    brand: 'brand',
    all: 'all'
  }
  // 仅作用于搜索页
  if (pageName !== 'page_search') return {}
  const isStoreSearch = type === SEARCH_TYPE_ENUMS.store // 判断是否为店铺搜索
  const isBrandSearch = type === SEARCH_TYPE_ENUMS.brand // 判断是否为专题搜索

  // 如果type为空，也视为全站搜索
  const allSearchPageName = [
    [undefined, 'page_search'],
    ['', 'page_search'],
    [SEARCH_TYPE_ENUMS.all, 'page_search'],
  ]
  const searchPageNameMap = new Map([
    ...allSearchPageName,
    [SEARCH_TYPE_ENUMS.store, 'page_store_search'],
    [SEARCH_TYPE_ENUMS.brand, 'page_chanel_search'],
  ])
  return {
    isStoreSearch, // 判断是否为店铺搜索
    isBrandSearch, // 判断是否为专题搜索
    goodsItemInfoPageKey: searchPageNameMap.get(type) ?? pageName, // 获取商品项pageKey参数
    showSelectOnLongClick: !isStoreSearch && !isBrandSearch, // 长按反馈flag
    showFeedback: !isStoreSearch && !isBrandSearch, // 是否展示反馈
    showSearchFilters: !isStoreSearch && !isBrandSearch, // 是否展示搜索筛选项
    hidePicTopNav: isStoreSearch || isBrandSearch, // 是否隐藏图文导航
    hasPicNavTop: !isStoreSearch && !isBrandSearch, // 是否有图文导航
    hideSearchCoupon: isStoreSearch || isBrandSearch, // 是否屏蔽搜索券
    hideListTopInfo: isStoreSearch || isBrandSearch, // 是否隐藏列表顶部信息
    hideRelatedSearch: isBrandSearch, // 是否隐藏搜了还搜
    hideFlowCellSearch: isBrandSearch, // 是否隐藏锦囊搜索
    hideFlowRankingList: isBrandSearch, // 是否隐藏榜单
  }
}

export const getFinalFlowRankingList = ({ sum, flowRankCcc, rankingList = [], ListComponent, catInfo }) => {
  // 不展示榜单组件
  const EmptyFlowRankingList = { FlowRankingList: { Data: null, Abt: null } }

  // 过滤 rankingList 中不符合条件的数据
  // cccx 配置的最小展示商品数
  // title 和 products 必须有值
  const rankingListFiltered = rankingList?.filter(item => {
    return item.deliverData.minimumProductImpressions <= sum && item.title && item.products.length
  }) || []

  if (!rankingListFiltered.length) {
    return EmptyFlowRankingList
  }
  // CTD-17978，列表榜单商品组件：需保留poskey：ListComponent，前端样式写死C分支；
  let ListComponentAbt = (catInfo.type === 'store' || ListComponent?.p?.ListComponent) ? 'FlowRankingListC' : null
  // switch (ListComponent?.p?.ListComponent) {
  //   case 'A': {
  //     ListComponentAbt = 'FlowRankingListA'
  //     break
  //   }
  //   case 'A1':
  //   case 'B1': {
  //     ListComponentAbt = 'FlowRankingListA1'
  //     break
  //   }
  //   case 'B':
  //     ListComponentAbt = 'FlowRankingListB'
  //     break
  //   case 'C': {
  //     ListComponentAbt = 'FlowRankingListC'
  //     break
  //   }
  // }
  if (!ListComponentAbt) {
    return EmptyFlowRankingList
  }

  // deliverPlaceItemsObject
  // 投放接口返回1,2,4,21,40 转成 { 0: null, 1: null, 3: null, 20: null, 39: null} }
  // 通过坑位索引值，判断坑位是否有数据，有数据则放置
  // 基于分页的逻辑，每页的坑位放置榜单最新的推荐商品数据
  if (flowRankCcc && rankingListFiltered.length > 0) {
    rankingListFiltered.map(
      ({
        deliverData,
        title,
        subTitle,
        rankingType,
        contentCarrierId,
        carrierData,
        products,
        hotListLanguage,
        recommendType,
        suggestedSalePriceType,
        customData
      }) => {
        flowRankCcc.deliverPlaceItemsObject[deliverData.deliverPlaceIndex] = {
          title,
          subTitle,
          rankingType,
          contentCarrierId,
          carrierData,
          goods: products,
          hotListLanguage,
          recommendType,
          suggestedSalePriceType,
          customData
        }
      }
    )
  }

  return {
    Data: getSearchDiff(catInfo)?.hideFlowRankingList ? {} : {
      flowRankingList: flowRankCcc,
    },
    Abt: {
      ListComponentAbt,
    }
  }
}

// filterBar queryParams
export const getFilterBarQueryParams = ({
  sort,
  daily,
  navId,
  child_cat_id,
  child_cat_id_void,
  attr_values,
  tsp_ids,
  attr_ids,
  brand_ids,
  attr_node_ids,
  tag_ids,
  min_price,
  max_price,
  pic_nav_id,
  pic_nav_type,
  pic_cate_id,
  pic_tab_id,
  kid_id,
  choose_ids
}) => {
  const queryParams = {}
  sort && (queryParams.sort = sort)
  daily && (queryParams.daily = daily)
  navId && (queryParams.navId = navId)
  kid_id && (queryParams.kid_id = kid_id)
  choose_ids && (queryParams.choose_ids = choose_ids);
  (child_cat_id && !child_cat_id_void) && (queryParams.child_cat_id = child_cat_id)
  tsp_ids && (Object.assign(queryParams, { tsp_ids }))
  attr_values && attr_ids && (Object.assign(queryParams, { attr_values, attr_ids, attr_node_ids }))
  tag_ids && (queryParams.tag_ids = tag_ids);
  (min_price || max_price || min_price === 0 || max_price === 0) && (Object.assign(queryParams, { min_price, max_price }));
  (pic_nav_id && pic_nav_type) && (Object.assign(queryParams, { pic_nav_id, pic_nav_type }))
  pic_tab_id && (queryParams.pic_tab_id = pic_tab_id)
  pic_cate_id && (queryParams.pic_cate_id = pic_cate_id)
  brand_ids && (queryParams.brand_ids = brand_ids)
  return queryParams
}

// 判断是否是ios
export const isIOS = () => {
  if (typeof window === 'undefined') return
  return /iPhone|iPad|iPod/i.test(navigator.userAgent)
}

/**
 * 点后刷图文导航，见 pageId=1557568306
 */
export const shouldRefreshPicTopNav = (picTopNav = [], pic_nav_id) => {
  // 获取所有的 navs来判断是否有当前的 nav_query_id，没有则清除。选中态的判断组件内已处理好，无需再处理
  const navs = (picTopNav || []).reduce((acc, cur) => {
    return acc.concat(cur.navs)
  }, [])
  // const pic_nav_id = payload.routeQuery?.pic_nav_id
  const isExist = (pic_nav_id) => navs.some((nav) => nav.nav_id === pic_nav_id)
  // 未选中图文时更新；选中图文时，只有当前选中的图文导航存在时才更新
  return pic_nav_id ? isExist(pic_nav_id) : true
}


export function getTopNavigation (obj) {
  const { pageName, nav_to_query, nav_jump_type, pic_nav_id } = obj
  const isJump = nav_jump_type?.toString() === '1'
  let exposeType = 'topNavigation' // top_navigation: 原始;  top_navigation_query: 搜索图文点击转query; top_navigation_jump: 真实/选品跳转
  pageName === 'page_search' && nav_to_query && (exposeType = 'top_navigation_query')
  nav_jump_type?.toString() === '1' && !pic_nav_id && (exposeType = 'top_navigation_jump')

  if (exposeType !== 'topNavigation') {
    const picNavData = ['nav_query_pos', 'nav_query_type', 'nav_query_id', 'nav_query_adp', 'nav_query_rec_type', 'pic_tab_id', 'pic_tab_type', 'pic_tab_pos'].reduce((arr, key) => {
      arr.push(obj[key] || '')
      return arr
    }, [])
    const top_nav_list = [...picNavData, obj?.nav_query_name || '']
    if (pageName === 'page_search' && nav_to_query) {
      // word_source：导购词的数据来源，来源是搜索算法上报0，人工干预报1，获取不到就上报“-”
      top_nav_list.push(['1', '2'].includes(obj?.nav_query_type?.toString()) ? '0' : '1')
    } else if (['page_real_class', 'page_select_class'].includes(pageName)) {
      top_nav_list.push('-', obj.from_page_cate_id || '-')
    }
    const topNavigation = top_nav_list.join('`')
    return {
      top_navigation: '',
      top_navigation_query: obj.nav_to_query ? topNavigation : '',
      top_navigation_jump: isJump ? topNavigation : '',
    }
  }

  // 未选中图文，则不上报
  if (!obj.pic_nav_id || !obj.pic_nav_type || !obj.pic_nav_pos) return {}
  const picNavData = ['pic_nav_pos', 'pic_nav_type', 'pic_nav_id', 'pic_nav_adp', 'pic_rec_type'].reduce((arr, key) => {
    arr.push(obj[key] || '')
    return arr
  }, [])
  const picNavTabs = ['pic_tab_id', 'pic_tab_type', 'pic_tab_pos'].reduce((arr, key) => {
    arr.push(obj[key] || '')
    return arr
  }, [])
  const pic_nav_name = obj?.pic_nav_name || ''
  const list = [...picNavData, ...picNavTabs]
  pic_nav_name && list.push(pic_nav_name)
  if (pageName === 'page_search') {
    // word_source：导购词的数据来源，来源是搜索算法上报0，人工干预报1，获取不到就上报“-”
    list.push(['1', '2'].includes(obj?.nav_query_type?.toString()) ? '0' : '1')
  } else if (['page_real_class', 'page_select_class'].includes(pageName)) {
    list.push('-', obj.from_page_cate_id || '-')
  }

  return {
    top_navigation: list.join('`'),
    top_navigation_query: '',
    top_navigation_jump: ''
  }
}
